<template>
  <div class="admin-container">
    <v-row class="justify-space-between">
      <v-col cols="12" md="4">
        <h1 class="col-secondary-1">Fotogalerie</h1>
      </v-col>
    </v-row>
    <div v-for="(t, tidx) in data" :key="t.id" class="mt-8">
      <span>{{ t.name }}</span>
      <span class="d-block col-secondary-3"
        ><span class="d-inline-block mr-2">{{ t.count_text }}</span>
        {{ t.date_of_record }}</span
      >
      <div class="photo-gallery-wrapper">
        <div v-for="(image, imageIdx) in t.documents" :key="image.id">
          <img
            v-if="imageIdx < 5"
            class="photo-gallery-single-item cursor-pointer"
            :src="image.path"
            :alt="image.name"
            @click="downloadDoc(image.document_id)"
          />
        </div>
        <button
          v-if="t.documents.length != 0"
          class="show-all-images"
          @click="
            $router.push({
              name: 'PhotoGallery-detail',
              params: { id: t.id },
            })
          "
        >
          <div>Zobrazit vše</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import authHandler from "../api/authHandler";
import permissionDeniedHandler from "../api/permissionDeniedHandler";
import bus from "../plugins/bus";
import errorHandler from "../api/errorHandler";
import photoGalleryService from "../api/photoGalleryService";
import documentService from "../api/documentService";

export default {
  data() {
    return {
      data: null,
    };
  },
  async created() {
    try {
      //   await this.$store.dispatch("getCurrentUser");
      if (this.getSelectedBuilding() != null) {
        await this.index();
      } else {
        this.$router.push({ name: "Dashboard" });
        bus.$emit("snackbar", {
          text: "Není vybrána žádná stavba.",
          color: "error",
        });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: "Login" });
      permissionDeniedHandler(error, this.$router);
    }
  },
  methods: {
    async downloadDoc(id) {
      try {
        bus.$emit("processing");
        await documentService.show(id);
        bus.$emit("processing", false);
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      return building;
    },
    async index() {
      try {
        bus.$emit("processing");
        const data = await photoGalleryService.index();
        this.data = data;
        console.log(data);
        bus.$emit("processing", false);
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    stahnout() {
      return {
        backgroundImage: `url(${require("@/assets/stahnout.svg")} !important`,
      };
    },
  },
};
</script>

<style></style>
