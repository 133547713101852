import req from "./request";

export default {
  index: async () => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      let url = `photo-gallery?building_id=${building.id}`;
      const { data } = await req.get(url, true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  show: async (id) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      const { data } = await req.get(
        `photo-gallery/${id}?building_id=${building.id}`,
        true
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  download: async (id) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      const data = await req.get(
        `photo-gallery/${id}/download?building_id=${building.id}`,
        true
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
